import React, { useContext } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";

const CollectionCategories = () => {
  const navigate = useNavigate();
  const { categories } = useContext(DataContext);

  // Handle Edit
  const handleEditCategory = (category) => {
    navigate("/add-new-category", {
      state: {
        id: category.id, // Pass the ID of the category
        name: category.name, // Pass the name of the category
        description: category.description, // Pass the description of the category
      },
    });
  };

  return (
    <div className="p-6 bg-gray-100">
      {/* Back Button */}
      <button
        className="mb-6 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 flex items-center"
        onClick={() => navigate(-1)}
      >
        ← Back
      </button>

      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Collection Categories</h1>
        <div className="flex items-center space-x-4">
          <button
            className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
            onClick={() => alert("Export functionality")}
          >
            Export
          </button>
          <button
            className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800"
            onClick={() => navigate("/add-new-category")} // Navigate to AddNewCategory for new category
          >
            + Add New Category
          </button>
        </div>
      </div>

      {/* Categories List */}
      <div className="bg-white shadow rounded p-4">
        {categories.map((category) => (
          <div
            key={category.id}
            className="flex justify-between items-center mb-4 border-b pb-2 cursor-pointer hover:bg-gray-50"
          >
            <input
              type="text"
              value={category.name}
              readOnly
              className="w-full bg-transparent border-none outline-none"
            />
            <div className="flex items-center space-x-4">
              {/* Edit Button */}
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => handleEditCategory(category)}
              >
                <FaEdit />
              </button>
              {/* Delete Button */}
              <button
                className="text-red-500 hover:text-red-700"
                onClick={() =>
                  window.confirm("Are you sure you want to delete this category?")
                }
              >
                <FaTrash />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CollectionCategories;
