// src/components/mutualFunds/SchemeDetails.js
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getThresholdDataBySchemeCode } from "../utilities/db";
import { HiArrowLeft } from "react-icons/hi";
import Carousel from "../utilities/Carousel";

const SchemeDetails = () => {
  const { schemeCode } = useParams();
  const navigate = useNavigate();

  const [thresholdData, setThresholdData] = useState([]);
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null); // For error handling

  useEffect(() => {
    const fetchThresholdData = async () => {
      try {
        const data = await getThresholdDataBySchemeCode(schemeCode);
        setThresholdData(data);
      } catch (err) {
        console.error("Error fetching threshold data:", err);
        setError("Failed to fetch Threshold Data.");
      } finally {
        setLoading(false);
      }
    };
    fetchThresholdData();
  }, [schemeCode]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <svg
          className="animate-spin h-10 w-10 text-blue-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 bg-red-100 text-red-700 rounded">
        {error}
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Back Button */}
      <button
        className="mb-4 flex items-center text-blue-500 hover:underline"
        onClick={() => navigate(-1)}
      >
        <HiArrowLeft size={20} /> <span className="ml-2">Back</span>
      </button>

      <h2 className="text-2xl font-bold mb-6">
        Threshold Data for Scheme Code: {schemeCode}
      </h2>

      {thresholdData.length === 0 ? (
        <div className="text-center text-gray-500">
          No Threshold Data available for this Scheme.
        </div>
      ) : (
        <Carousel>
          {thresholdData.map((record, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded shadow-md space-y-4"
            >
              <h3 className="text-xl font-semibold mb-2">
                Threshold Entry {index + 1}
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <span className="font-medium">Fund Code:</span> {record.fund_code}
                </div>
                <div>
                  <span className="font-medium">Scheme Code:</span> {record.scheme_code}
                </div>
                <div>
                  <span className="font-medium">Transaction Type:</span> {record.txn_type}
                </div>
                <div>
                  <span className="font-medium">System Frequency:</span> {record.sys_freq}
                </div>
                <div>
                  <span className="font-medium">System Frequency Option:</span> {record.sys_freq_opt}
                </div>
                <div>
                  <span className="font-medium">System Dates:</span> {record.sys_dates}
                </div>
                <div>
                  <span className="font-medium">Minimum Amount:</span> {record.min_amt}
                </div>
                <div>
                  <span className="font-medium">Maximum Amount:</span> {record.max_amt}
                </div>
                <div>
                  <span className="font-medium">Multiple Amount:</span> {record.multiple_amt}
                </div>
                <div>
                  <span className="font-medium">Minimum Units:</span> {record.min_units}
                </div>
                <div>
                  <span className="font-medium">Multiple Units:</span> {record.multiple_units}
                </div>
                <div>
                  <span className="font-medium">Minimum Installments:</span> {record.min_inst}
                </div>
                <div>
                  <span className="font-medium">Maximum Installments:</span> {record.max_inst}
                </div>
                <div>
                  <span className="font-medium">System Perpetual:</span> {record.sys_perpetual}
                </div>
                <div>
                  <span className="font-medium">Minimum Cumulative Amount:</span> {record.min_cum_amt}
                </div>
                <div>
                  <span className="font-medium">Start Date:</span> {record.start_date}
                </div>
                <div>
                  <span className="font-medium">End Date:</span> {record.end_date}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default SchemeDetails;
