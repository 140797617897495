// import React, { useContext, useState } from "react";
// import { DataContext } from "../../context/DataContext"; // Adjust the path to DataContext
// import { useNavigate } from "react-router-dom";
// import { FaSearch, FaFilter } from "react-icons/fa";

// const mockUsers = [
//   {
//     id: "SW01",
//     fullName: "John Doe",
//     email: "johndoe@gmail.com",
//     canNumber: "01234567890",
//     status: "Verified",
//   },
//   // Additional mock data...
// ];

// const UserManagement = () => {
//   const { usersData, loading, error } = useContext(DataContext); // Access data from context
//   const [selectedStatus, setSelectedStatus] = useState("All");
//   const [searchTerm, setSearchTerm] = useState("");
//   const [showFilterDropdown, setShowFilterDropdown] = useState(false);
//   const navigate = useNavigate();

//   if (loading) return <p>Loading users...</p>;
//   if (error) return <p>Error: {error.message}</p>;

//   // Combine fetched users and mock users
//   const combinedUsers = [...mockUsers, ...usersData];

//   // Filter logic
//   const filteredUsers = combinedUsers.filter((user) => {
//     const matchesStatus =
//       selectedStatus === "All" || user.status === selectedStatus;
//     const matchesSearch =
//       user.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       user.email?.toLowerCase().includes(searchTerm.toLowerCase());
//     return matchesStatus && matchesSearch;
//   });

//   const handleRowClick = (id) => {
//     navigate(`/user/${id}`);
//   };

//   return (
//     <div className="p-6 bg-gray-100">
//       <h1 className="text-2xl font-bold">User Management</h1>

//       {/* Filters and Search */}
//       <div className="flex justify-between items-center mb-6">
//         {/* Filter Dropdown */}
//         <div className="relative">
//           <button
//             className="bg-gray-200 px-4 py-2 rounded flex items-center space-x-2 hover:bg-gray-300"
//             onClick={() => setShowFilterDropdown(!showFilterDropdown)}
//           >
//             <FaFilter />
//             <span>Filters</span>
//           </button>
//           {showFilterDropdown && (
//             <div className="absolute mt-2 bg-white shadow rounded w-48">
//               {["All", "Verified", "Pending", "Rejected"].map((status) => (
//                 <div
//                   key={status}
//                   className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
//                     selectedStatus === status
//                       ? "font-bold text-blue-500"
//                       : "text-gray-700"
//                   }`}
//                   onClick={() => {
//                     setSelectedStatus(status);
//                     setShowFilterDropdown(false);
//                   }}
//                 >
//                   {status}
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>

//         {/* Search Box */}
//         <div className="flex items-center space-x-2 border px-4 py-2 rounded bg-white">
//           <FaSearch />
//           <input
//             type="text"
//             placeholder="Search Users"
//             className="outline-none"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//           />
//         </div>
//       </div>

//       {/* Users Table */}
//       <div className="bg-white shadow rounded overflow-hidden">
//         <table className="w-full">
//           <thead className="bg-gray-100">
//             <tr>
//               <th>User ID</th>
//               <th>Full Name</th>
//               <th>Email</th>
//               <th>Status</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredUsers.map((user) => (
//               <tr
//                 key={user.id}
//                 className="border-b hover:bg-gray-50 cursor-pointer"
//                 onClick={() => handleRowClick(user.id)}
//               >
//                 <td>{user.id}</td>
//                 <td>{user.fullName || "N/A"}</td>
//                 <td>{user.email || "N/A"}</td>
//                 <td>{user.status || "Unknown"}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default UserManagement;






import React, { useState, useContext } from "react";
  import { DataContext } from "../../context/DataContext";
  import { useNavigate } from "react-router-dom";
  import { FaSearch, FaFilter } from "react-icons/fa";

  const mockUsers = [
    {
      id: "SW01",
      fullName: "John Doe",
      email: "johndoe@gmail.com",
      canNumber: "01234567890",
      status: "Verified",
    },
    {
      id: "SW02",
      fullName: "Jane Smith",
      email: "janesmith@gmail.com",
      canNumber: "09876543210",
      status: "Pending",
    },
    {
      id: "SW03",
      fullName: "Samuel Green",
      email: "samuelgreen@gmail.com",
      canNumber: "1234509876",
      status: "Verified",
    },
    {
      id: "SW04",
      fullName: "Emily Davis",
      email: "emilydavis@gmail.com",
      canNumber: "2345609871",
      status: "Verified",
    },
    {
      id: "SW05",
      fullName: "Michael Brown",
      email: "michaelbrown@gmail.com",
      canNumber: "3456709872",
      status: "Rejected",
    },
  ];
  
  
  const UserManagement = () => {
    const [selectedStatus, setSelectedStatus] = useState("All"); // Selected filter
    const [searchTerm, setSearchTerm] = useState(""); // Search term
    const [showFilterDropdown, setShowFilterDropdown] = useState(false); // Toggle filter dropdown
    const navigate = useNavigate(); // Navigate function for routing

    const { usersData } = useContext(DataContext);
    console.log("usersData", usersData)
  
  
    // Filter logic
    const filteredUsers = mockUsers.filter((user) => {
      const matchesStatus =
        selectedStatus === "All" || user.status === selectedStatus;
      const matchesSearch =
        user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesStatus && matchesSearch;
    });
  
    const handleRowClick = (id) => {
      navigate(`/user/${id}`); // Navigate to the user detail page
    };
  
    return (
      <div className="p-6 bg-gray-100">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">User Management</h1>
        </div>
  
        {/* Info Cards */}
        <div className="grid grid-cols-3 gap-4 mb-6">
          {["Total Users", "Verified Users", "New Users"].map((label, index) => (
            <div key={index} className="bg-white shadow p-4 border rounded flex items-center">
              <div className="p-4 w-10 h-10 flex items-center justify-center bg-blue-100 text-blue-600 rounded-full">
                <span>👤</span>
              </div>
              <div className="ml-4">
                <p className="text-sm font-semibold text-gray-500">{label}</p>
                <p className="text-xl font-bold">40,689</p>
              </div>
            </div>
          ))}
        </div>
  
        {/* Filters and Search */}
        <div className="flex justify-between items-center mb-6">
          {/* Filter Dropdown */}
          <div className="relative">
            <button
              className="bg-gray-200 px-4 py-2 rounded flex items-center space-x-2 hover:bg-gray-300"
              onClick={() => setShowFilterDropdown(!showFilterDropdown)}
            >
              <FaFilter />
              <span>Filters</span>
            </button>
            {showFilterDropdown && (
              <div className="absolute mt-2 bg-white shadow rounded w-48">
                {["All", "Verified", "Pending", "Rejected"].map((status) => (
                  <div
                    key={status}
                    className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                      selectedStatus === status
                        ? "font-bold text-blue-500"
                        : "text-gray-700"
                    }`}
                    onClick={() => {
                      setSelectedStatus(status);
                      setShowFilterDropdown(false);
                    }}
                  >
                    {status}
                  </div>
                ))}
              </div>
            )}
          </div>
  
          {/* Search Box */}
          <div className="flex items-center space-x-2 border px-4 py-2 rounded bg-white">
            <FaSearch />
            <input
              type="text"
              placeholder="Search Users"
              className="outline-none"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
  
        {/* Export Button */}
        <div className="flex justify-end mb-4">
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Export
          </button>
        </div>
  
        {/* Users Table */}
        <div className="bg-white shadow rounded overflow-hidden">
          <table className="w-full">
            <thead className="bg-gray-100">
              <tr>
                <th className="text-left px-4 py-3 border-b">User ID</th>
                <th className="text-left px-4 py-3 border-b">User Full Name</th>
                <th className="text-left px-4 py-3 border-b">Email</th>
                <th className="text-left px-4 py-3 border-b">CAN Number</th>
                <th className="text-left px-4 py-3 border-b">Status</th>
                <th className="text-left px-4 py-3 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user) => (
                  <tr
                    key={user.id}
                    className="border-b hover:bg-gray-50 cursor-pointer"
                    onClick={() => handleRowClick(user.id)} // Navigate on row click
                  >
                    <td className="px-4 py-4">{user.id}</td>
                    <td className="px-4 py-4">{user.fullName}</td>
                    <td className="px-4 py-4">{user.email}</td>
                    <td className="px-4 py-4">{user.canNumber}</td>
                    <td className="px-4 py-4">
                      <span
                        className={`px-3 py-1 rounded text-white ${
                          user.status === "Verified"
                            ? "bg-green-500"
                            : user.status === "Pending"
                            ? "bg-orange-500"
                            : "bg-red-500"
                        }`}
                      >
                        {user.status}
                      </span>
                    </td>
                    <td
                      className="px-4 py-4 text-blue-500 hover:underline"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click event
                        handleRowClick(user.id); // Navigate to user detail on button click
                      }}
                    >
                      Review
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="px-4 py-4 text-center" colSpan={6}>
                    No users found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  
  export default UserManagement;
  