import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { DataContext } from "../../context/DataContext";

const CreateQuestion = ({ questionId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { createQuestion, updateQuestion, questions } = useContext(DataContext);

  const { questionText = "", options = [] } = location.state || {};

  // Initialize state with existing data if editing
  const [question, setQuestion] = useState(questionText);
  const [optionsData, setOptionsData] = useState(
    Array.isArray(options) ? options : ["", ""]
  ); // Ensure at least two options
  const [correctOption, setCorrectOption] = useState("");

  useEffect(() => {
    if (questionId && questions[questionId]) {
      const existingQuestion = questions[questionId];
      setQuestion(existingQuestion.question || "");
      setOptionsData(existingQuestion.options || ["", ""]);
      setCorrectOption(existingQuestion.correctOption || "");
    }
  }, [questionId, questions]);

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...optionsData];
    updatedOptions[index] = value;
    setOptionsData(updatedOptions);
  };

  const handleAddOption = () => {
    setOptionsData([...optionsData, ""]);
  };

  const handleRemoveOption = (index) => {
    if (optionsData.length > 2) { // Ensure at least two options
      const updatedOptions = optionsData.filter((_, i) => i !== index);
      setOptionsData(updatedOptions);
      if (correctOption === index.toString()) {
        setCorrectOption("");
      }
    }
  };

  const handleCorrectOptionChange = (e) => {
    setCorrectOption(e.target.value);
  };

  const handleSubmit = async () => {
    if (question.trim() && optionsData.length >= 2) {
      const newQuestion = {
        question: question.trim(),
        options: optionsData.map((opt) => opt.trim()),
        correctOption,
      };
      try {
        if (questionId) {
          await updateQuestion(questionId, newQuestion);
        } else {
          await createQuestion(newQuestion);
        }
        navigate("/risk-analyzer");
      } catch (err) {
        // Handle error (optional)
        console.error("Error submitting question:", err);
      }
    } else {
      // Optionally, add validation messages
      alert("Please provide a question and at least two options.");
    }
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">
          {questionId ? "Edit" : "Create"} Question
        </h1>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">Question</label>
        <input
          type="text"
          className="w-full p-2 border rounded-md"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Enter your question here"
        />
      </div>

      {optionsData.map((option, index) => (
        <div key={index} className="flex items-center mb-4">
          <input
            type="text"
            className="w-full p-2 border rounded-md"
            value={option}
            onChange={(e) => handleOptionChange(index, e.target.value)}
            placeholder={`Option ${index + 1}`}
          />
          <button
            className="ml-2 text-red-500"
            onClick={() => handleRemoveOption(index)}
            disabled={optionsData.length <= 2} // Prevent removing below 2 options
            title={
              optionsData.length <= 2
                ? "At least two options are required."
                : "Remove Option"
            }
          >
            <FaTrash />
          </button>
        </div>
      ))}

      <button
        className="bg-blue-500 text-white px-4 py-2 rounded-md mb-4"
        onClick={handleAddOption}
      >
        Add Option
      </button>

      {/* Optional: Select Correct Option */}
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">
          Correct Option
        </label>
        <select
          className="w-full p-2 border rounded-md"
          value={correctOption}
          onChange={handleCorrectOptionChange}
        >
          <option value="">Select Correct Option</option>
          {optionsData.map((option, index) => (
            <option key={index} value={index.toString()}>
              Option {index + 1}
            </option>
          ))}
        </select>
      </div>

      <div className="mt-4">
        <button
          className="bg-green-500 text-white px-4 py-2 rounded-md"
          onClick={handleSubmit}
        >
          {questionId ? "Update" : "Create"} Question
        </button>
      </div>
    </div>
  );
};

export default CreateQuestion;
