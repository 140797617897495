// src/components/RiskAnalyzer.js

import React, { useState, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import icon3 from "../dashboard/assets/icon-3.svg";
import icon4 from "../dashboard/assets/icon-4.svg";
import {
  FaPlus,
  FaArrowRight,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { DataContext } from "../../context/DataContext";

const RiskAnalyzer = () => {
  const navigate = useNavigate();
  const { questions, riskCategories } = useContext(DataContext);
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 5;

  // Convert questions object to array with id
  const questionsArray = useMemo(() => {
    return Object.entries(questions).map(([id, data]) => ({
      id,
      ...data,
    }));
  }, [questions]);

  // Pagination logic
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = questionsArray.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  const totalPages = Math.ceil(questionsArray.length / questionsPerPage);

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Risk Analyzer</h1>
        <button
          onClick={() => navigate("/create-question")}
          className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-md"
        >
          <FaPlus className="mr-2" /> Create New Question
        </button>
      </div>

      <div className="flex space-x-4 mb-6">
        <div className="flex items-center justify-between space-x-4 bg-white p-4 shadow-2xl rounded-md w-fit">
          <div className="flex flex-col items-start space-y-2">
            <p className="text-lg font-semibold">Total Questions</p>
            <strong>{questionsArray.length}</strong>
          </div>
          <img src={icon3} alt="Total Questions" />
        </div>
        <div className="flex items-center justify-between space-x-4 bg-white p-4 shadow-2xl rounded-md w-fit">
          <div className="flex flex-col items-start space-y-2 ">
            <p className="text-lg font-semibold">Total Categories</p>
            <strong>{riskCategories.length}</strong>
          </div>
          <img src={icon4} alt="Total Categories" />
        </div>
      </div>

      <div
        onClick={() => navigate("/risk-profiles")}
        className="flex justify-between items-center mb-4 rounded-lg shadow-lg p-6 cursor-pointer"
        style={{
          background:
            "linear-gradient(to bottom right, rgba(255, 92, 0, 0.20) 0%, rgba(255, 115, 0, 0.20) 19%, rgba(255, 152, 0, 0.20) 35%, rgba(255, 152, 0, 0.20) 43%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(255, 92, 0, 0.20) 0%, rgba(255, 115, 0, 0.20) 19%, rgba(255, 152, 0, 0.20) 35%, rgba(255, 152, 0, 0.20) 43%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(255, 92, 0, 0.20) 0%, rgba(255, 115, 0, 0.20) 19%, rgba(255, 152, 0, 0.20) 35%, rgba(255, 152, 0, 0.20) 43%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(255, 92, 0, 0.20) 0%, rgba(255, 115, 0, 0.20) 19%, rgba(255, 152, 0, 0.20) 35%, rgba(255, 152, 0, 0.20) 43%) top right / 50% 50% no-repeat",
        }}
      >
        <div className="flex flex-col items-start space-y-2 ">
          <h2 className="text-xl font-semibold">
            Risk Profiles (Result Categories)
          </h2>
          <div className="bg-[#ffffff] opacity-70 px-2 py-1">
            <p className="text-xs font-normal">
              Total Categories: {riskCategories.length}
            </p>
          </div>
        </div>

        <div
          className="cursor-pointer rounded-full p-4"
          style={{
            background:
              "linear-gradient(to bottom right, #FF5C00 0%, #FF7300 19%, #FF9800 35%, #FF9800 43%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #FF5C00 0%, #FF7300 19%, #FF9800 35%, #FF9800 43%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #FF5C00 0%, #FF7300 19%, #FF9800 35%, #FF9800 43%) top left / 50% 50% no-repeat, linear-gradient(to top right, #FF5C00 0%, #FF7300 19%, #FF9800 35%, #FF9800 43%) top right / 50% 50% no-repeat",
          }}
          onClick={() => navigate("/risk-profiles")}
        >
          <FaArrowRight className="text-white" size={20} />
        </div>
      </div>

      <table className="w-full bg-white shadow-md rounded-lg">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-4 text-center">S No.</th>
            <th className="p-4 text-center">Questions</th>
            <th className="p-4 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentQuestions.map((question, index) => (
            <tr key={question.id} className="border-b">
              <td className="p-4 text-center">
                {index + 1 + (currentPage - 1) * questionsPerPage}
              </td>
              <td className="p-4 text-center">{question.question}</td>
              <td className="p-4 text-center">
                <button
                  className="bg-orange-500 text-white px-4 py-2 rounded-md"
                  onClick={() =>
                    navigate(`/edit-question/${question.id}`)
                  }
                >
                  Review
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-end items-center mt-4">
        <FaChevronLeft
          className={`cursor-pointer ${
            currentPage === 1 ? "text-gray-400" : ""
          }`}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        />
        {[...Array(totalPages)].map((_, i) => (
          <button
            key={i}
            className={`px-2 ${currentPage === i + 1 ? "font-bold" : ""}`}
            onClick={() => setCurrentPage(i + 1)}
          >
            {i + 1}
          </button>
        ))}
        <FaChevronRight
          className={`cursor-pointer ${
            currentPage === totalPages ? "text-gray-400" : ""
          }`}
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
        />
      </div>
    </div>
  );
};

export default RiskAnalyzer;
