import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";  // Import useLocation
import { FaArrowLeft } from "react-icons/fa";
import { DataContext } from "../../context/DataContext";

const AddCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();  // Access the location object
  const { id } = useParams();
  const { riskCategories, createRiskCategory, updateRiskCategory, fetchRiskCategories } = useContext(DataContext);

  // Set initial state for category name and description
  const [categoryName, setCategoryName] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (id) {
      fetchRiskCategories();  // Fetch categories if ID exists
      const category = riskCategories.find((category) => category.id === id);
      if (category) {
        setCategoryName(category.name);
        setDescription(category.description);
      }
    } else if (location.state) {
      // If no ID (for creating a new category), check if state is passed (for editing)
      const { name, description } = location.state;
      setCategoryName(name);
      setDescription(description);
    }
  }, [id, riskCategories, fetchRiskCategories, location.state]);

  const handleSave = async () => {
    if (id) {
      const response = await updateRiskCategory(id, { name: categoryName, description });
      alert(response.message);
    } else {
      const response = await createRiskCategory(categoryName, description);
      alert(response.message);
    }
    navigate(-1);  // Navigate back after saving
  };

  return (
    <div className="p-8">
      <button onClick={() => navigate(-1)} className="mb-4 text-blue-500 flex items-center">
        <FaArrowLeft className="mr-2" /> Back
      </button>

      <h1 className="text-2xl font-bold mb-6">{id ? 'Edit Risk Category' : 'Add New Risk Category'}</h1>

      <div className="mb-4">
        <label className="block text-lg">Category Name</label>
        <input
          type="text"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          className="w-full border p-2 rounded-md"
        />
      </div>

      <div className="mb-4">
        <label className="block text-lg">Description</label>
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full border p-2 rounded-md"
        />
      </div>

      <div className="flex space-x-4">
        <button onClick={() => navigate(-1)} className="bg-gray-500 text-white px-4 py-2 rounded-md">
          Discard
        </button>
        <button onClick={handleSave} className="bg-green-500 text-white px-4 py-2 rounded-md">
          Save
        </button>
      </div>
    </div>
  );
};

export default AddCategory;
