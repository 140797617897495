import React from "react";
import image from './login.svg'
const Logout = () => {
  const handleLogout = () => {
    console.log("User logged out");
  };

  const handleCancel = () => {
    console.log("User decided to stay logged in");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="flex flex-col md:flex-row bg-white rounded-lg overflow-hidden">
        <div className="hidden md:flex items-center justify-center  p-6">
          <img
            src={image}
            alt="Logout Illustration"
            className="w-72 h-auto"
          />
        </div>
        <div className="p-8 md:p-12 w-full md:w-96 bg-orange-100">
          <h2 className="text-2xl font-bold text-gray-800">Log out of your account</h2>
          <p className="text-gray-600 mb-6">
            Are you sure you want to log out of Solid Wealth Admin?
          </p>
          <button
            onClick={handleLogout}
            className="w-full bg-black text-white py-2 px-4 rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2 mb-4"
          >
            Logout
          </button>
          <button
            onClick={handleCancel}
            className="w-full text-orange-500 py-2 px-4 rounded-lg hover:underline"
          >
            No, keep me logged in
          </button>
        </div>
      </div>
    </div>
  );
};

export default Logout;
