// src/components/mutualFunds/MutualFundsManagement.js
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineCube, HiOutlineTrendingUp } from "react-icons/hi";
import { FaFilter, FaSearch, FaUpload } from "react-icons/fa";
import { FixedSizeList as List } from "react-window";
import Pagination from "../utilities/Pagination"; // Ensure this component handles pagination appropriately
import { fetchAndParseExcel } from "../utilities/excelParser";

import { addBulkSchemeData, getAllSchemeData, clearSchemeData, addBulkThresholdData, clearThresholdData, getAllThresholdData } from "../utilities/db";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const MutualFundsManagement = () => {
  const navigate = useNavigate();

  // State variables
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50; // Adjust as needed

  const [schemeData, setSchemeData] = useState([]);

  // New state variables for upload progress and error handling
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const filterOptions = ["All", "Mid Cap", "Large Cap", "Small Cap"]; // Adjust based on scheme categories

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const handleFilterChange = (option) => {
    setSelectedFilter(option);
    setShowFilterOptions(false);
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const handleReviewClick = (fund) => {
    navigate(`/scheme-details/view/${fund.scheme_code}`);
  };

  // References to hidden file inputs
  const fileInputSchemeRef = useRef(null);
  const fileInputThresholdRef = useRef(null);

  // Handler for Upload Scheme button
  const handleUploadSchemeClick = () => {
    fileInputSchemeRef.current.click();
  };

  // Handler for Upload Threshold button
  const handleUploadThresholdClick = () => {
    fileInputThresholdRef.current.click();
  };

 // src/components/mutualFunds/MutualFundsManagement.js

const handleFileUpload = async (e, type) => {
  const file = e.target.files[0];
  if (!file) {
    toast.warning("No file selected.");
    return;
  }

  console.log(`Starting upload for ${type} data...`);

  // Reset progress and error states
  setUploading(true);
  setProgress(0);
  setErrorMessage("");

  try {
    // Correctly pass the File object to fetchAndParseExcel
    const jsonData = await fetchAndParseExcel(file);

    console.log(`Total records found: ${jsonData.length}`);

    if (jsonData.length === 0) {
      throw new Error("No data found in the Excel sheet.");
    }

    // Determine which data to process
    if (type === "scheme") {
      // Clear existing SchemeData
      await clearSchemeData();

      // Map SchemeData without 'id'
      const mappedSchemeData = jsonData.map((item) => ({
        // Remove 'id'
        scheme_code: (item["scheme_code"] || "").toUpperCase(),
        fund_code: (item["fund_code"] || "").toUpperCase(),
        plan_name: item["plan_name"] || "",
        scheme_type: item["scheme_type"] || "",
        plan_type: item["plan_type"] || "",
        plan_opt: item["plan_opt"] || "",
        div_opt: item["div_opt"] || "",
        amfi_id: item["amfi_id"] || "",
        pri_isin: item["pri_isin"] || "",
        sec_isin: item["sec_isin"] || "",
        nfo_start: item["nfo_start"] || "",
        nfo_end: item["nfo_end"] || "",
        allot_date: item["allot_date"] || "",
        reopen_date: item["reopen_date"] || "",
        maturity_date: item["maturity_date"] || "",
        entry_load: item["entry_load"] || "",
        exit_load: item["exit_load"] || "",
        pur_allowed: item["pur_allowed"] || "",
        nfo_allowed: item["nfo_allowed"] || "",
        redeem_allowed: item["redeem_allowed"] || "",
        sip_allowed: item["sip_allowed"] || "",
        switch_out_allowed: item["switch_out_allowed"] || "",
        Switch_In_Allowed: item["Switch_In_Allowed"] || "",
        stp_out_allowed: item["stp_out_allowed"] || "",
        stp_in_allowed: item["stp_in_allowed"] || "",
        swp_allowed: item["swp_allowed"] || "",
        Demat_Allowed: item["Demat_Allowed"] || "",
        Catg_ID: item["Catg ID"] || "",
        Sub_Catg_ID: item["Sub-Catg ID"] || "",
        Scheme_Flag: item["Scheme Flag"] || "",
      }));

      // Add bulk SchemeData
      await addBulkSchemeData(mappedSchemeData);

      // Update state
      const updatedSchemeData = await getAllSchemeData();
      setSchemeData(updatedSchemeData);

      toast.success(
        `${mappedSchemeData.length} Scheme records successfully uploaded to SchemeData in IndexedDB.`
      );
    } else if (type === "threshold") {
      // Clear existing ThresholdData
      await clearThresholdData();

      // Map ThresholdData without 'id'
      const mappedThresholdData = jsonData.map((item) => ({
        // Remove 'id'
        fund_code: (item["fund_code"] || "").toUpperCase(),
        scheme_code: (item["scheme_code"] || "").toUpperCase(),
        txn_type: item["txn_type"] || "",
        sys_freq: item["sys_freq"] || "",
        sys_freq_opt: item["sys_freq_opt"] || "",
        sys_dates: item["sys_dates"] || "",
        min_amt: item["min_amt"] || "",
        max_amt: item["max_amt"] || "",
        multiple_amt: item["multiple_amt"] || "",
        min_units: item["min_units"] || "",
        multiple_units: item["multiple_units"] || "",
        min_inst: item["min_inst"] || "",
        max_inst: item["max_inst"] || "",
        sys_perpetual: item["sys_perpetual"] || "",
        min_cum_amt: item["min_cum_amt"] || "",
        start_date: item["start_date"] || "",
        end_date: item["end_date"] || "",
      }));

      // Add bulk ThresholdData
      await addBulkThresholdData(mappedThresholdData);

      toast.success(
        `${mappedThresholdData.length} Threshold records successfully uploaded to ThresholdData in IndexedDB.`
      );
    }

    // Clear the file input value to allow re-uploading the same file if needed
    e.target.value = null;
  } catch (error) {
    console.error("Error uploading file:", error);
    setErrorMessage("Error uploading file. Please check the console for details.");
    toast.error("Error uploading file. Please check the console for details.");
  } finally {
    setUploading(false);
  }
};


  // Fetch SchemeData from IndexedDB on component mount
  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllSchemeData();
      setSchemeData(data);
    };
    fetchData();
  }, []);

  // Filtered data based on search and filter
  const filteredData = schemeData.filter((fund) => {
    const matchesFilter = selectedFilter === "All" || fund.scheme_type === selectedFilter;
    const matchesSearch =
      fund.scheme_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      fund.fund_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      fund.plan_name.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesFilter && matchesSearch;
  });

  // Pagination calculations
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Row renderer for react-window
  const Row = useCallback(
    ({ index, style }) => {
      const fund = paginatedData[index];
      if (!fund) return null;
      return (
        <div
          className={`flex border-b border-gray-200 p-2 ${
            index % 2 === 0 ? "bg-white" : "bg-gray-50"
          }`}
          style={style}
        >
          <div className="flex-1 text-center">{(currentPage - 1) * itemsPerPage + index + 1}</div>
          <div className="flex-1 text-center">{fund.scheme_code}</div>
          <div className="flex-1 text-center">{fund.fund_code}</div>
          <div className="flex-1 text-center">{fund.plan_name}</div>
          <div className="flex-1 text-center">{fund.scheme_type}</div>
          <div className="flex-1 text-center">{fund.plan_type}</div>
          <div
            className="flex-1 text-blue-500 text-center cursor-pointer hover:underline"
            onClick={() => handleReviewClick(fund)}
          >
            Review
          </div>
        </div>
      );
    },
    [paginatedData, currentPage, handleReviewClick]
  );

  return (
    <div className="p-6 bg-gray-100">
      <ToastContainer />

      {/* Hidden File Inputs */}
      <input
        type="file"
        accept=".xlsx, .xls"
        ref={fileInputSchemeRef}
        style={{ display: "none" }}
        onChange={(e) => handleFileUpload(e, "scheme")}
      />
      <input
        type="file"
        accept=".xlsx, .xls"
        ref={fileInputThresholdRef}
        style={{ display: "none" }}
        onChange={(e) => handleFileUpload(e, "threshold")}
      />

      {/* Header */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <h1 className="text-2xl font-bold mb-4 md:mb-0">Mutual Funds Management</h1>
        <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
          <button
            className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 flex items-center space-x-2"
            onClick={() => navigate("/trending-funds")}
          >
            <HiOutlineTrendingUp size={20} /> <span>Edit Trending Funds</span>
          </button>
          <button
            className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800 flex items-center space-x-2"
            onClick={() => navigate("/collection-categories")}
          >
            <HiOutlineCube size={20} /> <span>Edit Collection Categories</span>
          </button>
        </div>
      </div>

      {/* Info Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div className="bg-white shadow p-4 rounded flex items-center">
          <div className="p-4 bg-orange-100 text-orange-600 rounded-full">
            <HiOutlineCube size={24} />
          </div>
          <div className="ml-4">
            <p className="text-sm text-gray-500">Total Scheme Records</p>
            <p className="text-xl font-bold">{schemeData.length}</p>
          </div>
        </div>
        <div className="bg-white shadow p-4 rounded flex items-center">
          <div className="p-4 bg-green-100 text-green-600 rounded-full">
            <HiOutlineTrendingUp size={24} />
          </div>
          <div className="ml-4">
            <p className="text-sm text-gray-500">Trending Funds</p>
            <p className="text-xl font-bold">103</p>
          </div>
        </div>
      </div>

      {/* Filters and Search */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
        {/* Filter Dropdown */}
        <div className="relative">
          <button
            className="bg-gray-200 px-4 py-2 rounded flex items-center space-x-2 hover:bg-gray-300"
            onClick={() => setShowFilterOptions(!showFilterOptions)}
          >
            <FaFilter />
            <span>{selectedFilter}</span>
          </button>
          {showFilterOptions && (
            <div className="absolute top-full left-0 mt-2 bg-white shadow rounded w-48 z-10">
              {filterOptions.map((option) => (
                <div
                  key={option}
                  className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                    selectedFilter === option ? "text-orange-500 font-bold" : ""
                  }`}
                  onClick={() => handleFilterChange(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Upload Buttons */}
        <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
          <button
            className={`bg-blue-400 text-white p-2 font-bold rounded-lg flex items-center space-x-2 ${
              uploading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-500"
            }`}
            onClick={handleUploadSchemeClick}
            disabled={uploading}
          >
            <FaUpload /> <span>Upload Scheme</span>
          </button>
          <button
            className={`bg-purple-400 text-white p-2 font-bold rounded-lg flex items-center space-x-2 ${
              uploading ? "opacity-50 cursor-not-allowed" : "hover:bg-purple-500"
            }`}
            onClick={handleUploadThresholdClick}
            disabled={uploading}
          >
            <FaUpload /> <span>Upload Threshold</span>
          </button>
        </div>

        {/* Search */}
        <div className="flex items-center space-x-2 border px-4 py-2 rounded bg-white w-full md:w-auto">
          <FaSearch />
          <input
            type="text"
            placeholder="Search by Scheme, Fund, or Plan"
            className="outline-none w-full md:w-64"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      {/* Progress Bar */}
      {uploading && (
        <div className="mb-4">
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium text-blue-700">{`Uploading: ${progress}%`}</span>
            <span className="text-sm font-medium text-blue-700">{`${progress}%`}</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-4">
            <div
              className="bg-blue-600 h-4 rounded-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      )}

      {/* Error Message */}
      {errorMessage && (
        <div className="mb-4 p-4 bg-red-100 text-red-700 rounded">
          {errorMessage}
        </div>
      )}

      {/* Scheme Data Table */}
      <div className="bg-white shadow rounded overflow-hidden">
        {/* Table Headers */}
        <div className="flex bg-gray-100 border-b p-2">
          <div className="flex-1 text-center font-semibold">S.No</div>
          <div className="flex-1 text-center font-semibold">Scheme Code</div>
          <div className="flex-1 text-center font-semibold">Fund Code</div>
          <div className="flex-1 text-center font-semibold">Plan Name</div>
          <div className="flex-1 text-center font-semibold">Scheme Type</div>
          <div className="flex-1 text-center font-semibold">Plan Type</div>
          <div className="flex-1 text-center font-semibold">Actions</div>
        </div>

        {/* Virtualized Table Body */}
        <List
          height={600} // Adjust based on your UI
          itemCount={paginatedData.length}
          itemSize={60} // Adjust row height as needed
          width="100%"
        >
          {Row}
        </List>
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default MutualFundsManagement;





// // src/components/MutualFundsManagement.js
// import React, { useState, useRef, useEffect, useCallback } from "react";
// import { useNavigate } from "react-router-dom";
// import { HiOutlineCube, HiOutlineTrendingUp } from "react-icons/hi";
// import { FaFilter, FaSearch, FaUpload } from "react-icons/fa";
// import { FixedSizeList as List } from "react-window";
// import Pagination from "../utilities/Pagination"; // Ensure this component handles pagination appropriately
// import { fetchAndParseExcel } from "../utilities/excelParser";

// import {   addBulkSchemeData,
//   getAllSchemeData,
//   clearSchemeData,
//   addBulkThresholdData,
//   clearThresholdData, 
//   getAllThresholdData,  } from "../utilities/db";
// import { ToastContainer, toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';

// const MutualFundsManagement = () => {
//   const navigate = useNavigate();

//   // State variables
//   const [searchTerm, setSearchTerm] = useState("");
//   const [selectedFilter, setSelectedFilter] = useState("All");
//   const [showFilterOptions, setShowFilterOptions] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 50; // Adjust as needed

//   const [schemeData, setSchemeData] = useState([]);

//   // New state variables for upload progress and error handling
//   const [uploading, setUploading] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [errorMessage, setErrorMessage] = useState("");

//   const filterOptions = ["All", "Mid Cap", "Large Cap", "Small Cap"]; // Adjust based on scheme categories

//   const handleSearchChange = (e) => setSearchTerm(e.target.value);

//   const handleFilterChange = (option) => {
//     setSelectedFilter(option);
//     setShowFilterOptions(false);
//   };

//   const handlePageChange = (page) => setCurrentPage(page);

//   const navigateToReview = (fund) => {
//     navigate(`/scheme-details/${fund.scheme_code}`, { state: { fund } });
//   };

//   // References to hidden file inputs
//   const fileInputSchemeRef = useRef(null);
//   const fileInputThresholdRef = useRef(null);

//   // Handler for Upload Scheme button
//   const handleUploadSchemeClick = () => {
//     fileInputSchemeRef.current.click();
//   };

//   // Handler for Upload Threshold button
//   const handleUploadThresholdClick = () => {
//     fileInputThresholdRef.current.click();
//   };

//   // Function to handle file upload with progress and error handling
//   const handleFileUpload = async (e, type) => {
//     const file = e.target.files[0];
//     if (!file) {
//       toast.warning("No file selected.");
//       return;
//     }

//     console.log(`Starting upload for ${type} data...`);

//     // Reset progress and error states
//     setUploading(true);
//     setProgress(0);
//     setErrorMessage("");

//     try {
//       const filePath = type === "scheme" ? "/data/scheme_data.xlsx" : "/data/threshold_data.xlsx";
//       const jsonData = await fetchAndParseExcel(filePath);

//       console.log(`Total records found: ${jsonData.length}`);

//       if (jsonData.length === 0) {
//         throw new Error("No data found in the Excel sheet.");
//       }

//       // Determine which data to process
//       if (type === "scheme") {
//         // Clear existing SchemeData
//         await clearSchemeData();

//         // Map SchemeData
//         const mappedSchemeData = jsonData.map((item) => ({
//           scheme_code: item.scheme_code || "",
//           fund_code: item.fund_code || "",
//           plan_name: item.plan_name || "",
//           scheme_type: item.scheme_type || "",
//           plan_type: item.plan_type || "",
//           plan_opt: item.plan_opt || "",
//           div_opt: item.div_opt || "",
//           amfi_id: item.amfi_id || "",
//           pri_isin: item.pri_isin || "",
//           sec_isin: item.sec_isin || "",
//           nfo_start: item.nfo_start || "",
//           nfo_end: item.nfo_end || "",
//           allot_date: item.allot_date || "",
//           reopen_date: item.reopen_date || "",
//           maturity_date: item.maturity_date || "",
//           entry_load: item.entry_load || "",
//           exit_load: item.exit_load || "",
//           pur_allowed: item.pur_allowed || "",
//           nfo_allowed: item.nfo_allowed || "",
//           redeem_allowed: item.redeem_allowed || "",
//           sip_allowed: item.sip_allowed || "",
//           switch_out_allowed: item.switch_out_allowed || "",
//           Switch_In_Allowed: item.Switch_In_Allowed || "",
//           stp_out_allowed: item.stp_out_allowed || "",
//           stp_in_allowed: item.stp_in_allowed || "",
//           swp_allowed: item.swp_allowed || "",
//           Demat_Allowed: item.Demat_Allowed || "",
//           Catg_ID: item["Catg ID"] || "",
//           Sub_Catg_ID: item["Sub-Catg ID"] || "",
//           Scheme_Flag: item["Scheme Flag"] || "",
//         }));

//         // Add bulk SchemeData
//         await addBulkSchemeData(mappedSchemeData);

//         // Update state
//         const updatedSchemeData = await getAllSchemeData();
//         setSchemeData(updatedSchemeData);

//         toast.success(
//           `${mappedSchemeData.length} Scheme records successfully uploaded to SchemeData in IndexedDB.`
//         );
//       } else if (type === "threshold") {
//         // Clear existing ThresholdData
//         await clearThresholdData();

//         // Map ThresholdData
//         const mappedThresholdData = jsonData.map((item) => ({
//           fund_code: item.fund_code || "",
//           scheme_code: item.scheme_code || "",
//           txn_type: item.txn_type || "",
//           sys_freq: item.sys_freq || "",
//           sys_freq_opt: item.sys_freq_opt || "",
//           sys_dates: item.sys_dates || "",
//           min_amt: item.min_amt || "",
//           max_amt: item.max_amt || "",
//           multiple_amt: item.multiple_amt || "",
//           min_units: item.min_units || "",
//           multiple_units: item.multiple_units || "",
//           min_inst: item.min_inst || "",
//           max_inst: item.max_inst || "",
//           sys_perpetual: item.sys_perpetual || "",
//           min_cum_amt: item.min_cum_amt || "",
//           start_date: item.start_date || "",
//           end_date: item.end_date || "",
//         }));
        

//         // Add bulk ThresholdData
//         await addBulkThresholdData(mappedThresholdData);

//         toast.success(
//           `${mappedThresholdData.length} Threshold records successfully uploaded to ThresholdData in IndexedDB.`
//         );
//       }

//       // Clear the file input value to allow re-uploading the same file if needed
//       e.target.value = null;
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       setErrorMessage("Error uploading file. Please check the console for details.");
//       toast.error("Error uploading file. Please check the console for details.");
//     } finally {
//       setUploading(false);
//     }
//   };

//   // Fetch SchemeData from IndexedDB on component mount
//   useEffect(() => {
//     const fetchData = async () => {
//       const data = await getAllSchemeData();
//       setSchemeData(data);
//     };
//     fetchData();
//   }, []);

//   // Filtered data based on search and filter
//   const filteredData = schemeData.filter((fund) => {
//     const matchesFilter = selectedFilter === "All" || fund.scheme_type === selectedFilter;
//     const matchesSearch =
//       fund.scheme_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       fund.fund_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       fund.plan_name.toLowerCase().includes(searchTerm.toLowerCase());
//     return matchesFilter && matchesSearch;
//   });

//   // Pagination calculations
//   const totalPages = Math.ceil(filteredData.length / itemsPerPage);
//   const paginatedData = filteredData.slice(
//     (currentPage - 1) * itemsPerPage,
//     currentPage * itemsPerPage
//   );

//   // Row renderer for react-window
//   const Row = useCallback(
//     ({ index, style }) => {
//       const fund = paginatedData[index];
//       if (!fund) return null;
//       return (
//         <div
//           className={`flex border-b border-gray-200 p-2 ${
//             index % 2 === 0 ? "bg-white" : "bg-gray-50"
//           }`}
//           style={style}
//         >
//           <div className="flex-1 text-center">{(currentPage - 1) * itemsPerPage + index + 1}</div>
//           <div className="flex-1 text-center">{fund.scheme_code}</div>
//           <div className="flex-1 text-center">{fund.fund_code}</div>
//           <div className="flex-1 text-center">{fund.plan_name}</div>
//           <div className="flex-1 text-center">{fund.scheme_type}</div>
//           <div className="flex-1 text-center">{fund.plan_type}</div>
//           <div
//             className="flex-1 text-blue-500 text-center cursor-pointer hover:underline"
//             onClick={() => navigateToReview(fund)}
//           >
//             Review
//           </div>
//         </div>
//       );
//     },
//     [paginatedData, currentPage, navigateToReview]
//   );

//   return (
//     <div className="p-6 bg-gray-100">
//       <ToastContainer />

//       {/* Hidden File Inputs */}
//       <input
//         type="file"
//         accept=".xlsx, .xls"
//         ref={fileInputSchemeRef}
//         style={{ display: "none" }}
//         onChange={(e) => handleFileUpload(e, "scheme")}
//       />
//       <input
//         type="file"
//         accept=".xlsx, .xls"
//         ref={fileInputThresholdRef}
//         style={{ display: "none" }}
//         onChange={(e) => handleFileUpload(e, "threshold")}
//       />

//       {/* Header */}
//       <div className="flex flex-col md:flex-row justify-between items-center mb-6">
//         <h1 className="text-2xl font-bold mb-4 md:mb-0">Mutual Funds Management</h1>
//         <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
//           <button
//             className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 flex items-center space-x-2"
//             onClick={() => navigate("/trending-funds")}
//           >
//             <HiOutlineTrendingUp size={20} /> <span>Edit Trending Funds</span>
//           </button>
//           <button
//             className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800 flex items-center space-x-2"
//             onClick={() => navigate("/collection-categories")}
//           >
//             <HiOutlineCube size={20} /> <span>Edit Collection Categories</span>
//           </button>
//         </div>
//       </div>

//       {/* Info Cards */}
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
//         <div className="bg-white shadow p-4 rounded flex items-center">
//           <div className="p-4 bg-orange-100 text-orange-600 rounded-full">
//             <HiOutlineCube size={24} />
//           </div>
//           <div className="ml-4">
//             <p className="text-sm text-gray-500">Total Scheme Records</p>
//             <p className="text-xl font-bold">{schemeData.length}</p>
//           </div>
//         </div>
//         <div className="bg-white shadow p-4 rounded flex items-center">
//           <div className="p-4 bg-green-100 text-green-600 rounded-full">
//             <HiOutlineTrendingUp size={24} />
//           </div>
//           <div className="ml-4">
//             <p className="text-sm text-gray-500">Trending Funds</p>
//             <p className="text-xl font-bold">103</p>
//           </div>
//         </div>
//       </div>

//       {/* Filters and Search */}
//       <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
//         {/* Filter Dropdown */}
//         <div className="relative">
//           <button
//             className="bg-gray-200 px-4 py-2 rounded flex items-center space-x-2 hover:bg-gray-300"
//             onClick={() => setShowFilterOptions(!showFilterOptions)}
//           >
//             <FaFilter />
//             <span>{selectedFilter}</span>
//           </button>
//           {showFilterOptions && (
//             <div className="absolute top-full left-0 mt-2 bg-white shadow rounded w-48 z-10">
//               {filterOptions.map((option) => (
//                 <div
//                   key={option}
//                   className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
//                     selectedFilter === option ? "text-orange-500 font-bold" : ""
//                   }`}
//                   onClick={() => handleFilterChange(option)}
//                 >
//                   {option}
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>

//         {/* Upload Buttons */}
//         <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
//           <button
//             className={`bg-blue-400 text-white p-2 font-bold rounded-lg flex items-center space-x-2 ${
//               uploading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-500"
//             }`}
//             onClick={handleUploadSchemeClick}
//             disabled={uploading}
//           >
//             <FaUpload /> <span>Upload Scheme</span>
//           </button>
//           <button
//             className={`bg-purple-400 text-white p-2 font-bold rounded-lg flex items-center space-x-2 ${
//               uploading ? "opacity-50 cursor-not-allowed" : "hover:bg-purple-500"
//             }`}
//             onClick={handleUploadThresholdClick}
//             disabled={uploading}
//           >
//             <FaUpload /> <span>Upload Threshold</span>
//           </button>
//         </div>

//         {/* Search */}
//         <div className="flex items-center space-x-2 border px-4 py-2 rounded bg-white w-full md:w-auto">
//           <FaSearch />
//           <input
//             type="text"
//             placeholder="Search by Scheme, Fund, or Plan"
//             className="outline-none w-full md:w-64"
//             value={searchTerm}
//             onChange={handleSearchChange}
//           />
//         </div>
//       </div>

//       {/* Progress Bar */}
//       {uploading && (
//         <div className="mb-4">
//           <div className="flex justify-between mb-1">
//             <span className="text-sm font-medium text-blue-700">{`Uploading: ${progress}%`}</span>
//             <span className="text-sm font-medium text-blue-700">{`${progress}%`}</span>
//           </div>
//           <div className="w-full bg-gray-200 rounded-full h-4">
//             <div
//               className="bg-blue-600 h-4 rounded-full"
//               style={{ width: `${progress}%` }}
//             ></div>
//           </div>
//         </div>
//       )}

//       {/* Error Message */}
//       {errorMessage && (
//         <div className="mb-4 p-4 bg-red-100 text-red-700 rounded">
//           {errorMessage}
//         </div>
//       )}

//       {/* Scheme Data Table */}
//       <div className="bg-white shadow rounded overflow-hidden">
//         {/* Table Headers */}
//         <div className="flex bg-gray-100 border-b p-2">
//           <div className="flex-1 text-center font-semibold">S.No</div>
//           <div className="flex-1 text-center font-semibold">Scheme Code</div>
//           <div className="flex-1 text-center font-semibold">Fund Code</div>
//           <div className="flex-1 text-center font-semibold">Plan Name</div>
//           <div className="flex-1 text-center font-semibold">Scheme Type</div>
//           <div className="flex-1 text-center font-semibold">Plan Type</div>
//           <div className="flex-1 text-center font-semibold">Actions</div>
//         </div>

//         {/* Virtualized Table Body */}
//         <List
//           height={600} // Adjust based on your UI
//           itemCount={paginatedData.length}
//           itemSize={60} // Adjust row height as needed
//           width="100%"
//         >
//           {Row}
//         </List>
//       </div>

//       {/* Pagination */}
//       <Pagination
//         currentPage={currentPage}
//         totalPages={totalPages}
//         onPageChange={handlePageChange}
//       />
//     </div>
//   );
// };

// export default MutualFundsManagement;

