import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const FundDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const fund = location.state?.fund;

  if (!fund) {
    return (
      <div className="p-6 bg-gray-100">
        <p>No fund details available</p>
        <button
          className="mt-4 bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
          onClick={() => navigate(-1)}
        >
          &larr; Back
        </button>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100">
      <div className="flex justify-between items-center mb-6">
        <button
          className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
          onClick={() => window.history.back()}
        >
          &larr; Back
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          onClick={() => console.log("Export functionality placeholder")}
        >
          Export
        </button>
      </div>

      <h1 className="text-2xl font-bold mb-6">{fund.name}</h1>

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <p className="mr-2">Trending Fund</p>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" className="sr-only peer" defaultChecked={true} />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:bg-blue-600"></div>
          </label>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div className="bg-white shadow p-4 rounded">
          <p className="text-sm text-gray-500">Mutual Fund Name</p>
          <p className="text-xl font-bold">{fund.name}</p>
        </div>
        <div className="bg-white shadow p-4 rounded">
          <p className="text-sm text-gray-500">Type</p>
          <p className="text-xl font-bold">{fund.type}</p>
        </div>
        <div className="bg-white shadow p-4 rounded">
          <p className="text-sm text-gray-500">Category</p>
          <p className="text-xl font-bold">{fund.category}</p>
        </div>
        <div className="bg-white shadow p-4 rounded">
          <p className="text-sm text-gray-500">Expense Ratio</p>
          <p className="text-xl font-bold">{fund.expenseRatio}</p>
        </div>
        <div className="bg-white shadow p-4 rounded">
          <p className="text-sm text-gray-500">AUM (Fund Size)</p>
          <p className="text-xl font-bold">{fund.aum}</p>
        </div>
        <div className="bg-white shadow p-4 rounded">
          <p className="text-sm text-gray-500">Rating</p>
          <p className="text-xl font-bold">{fund.rating}</p>
        </div>
        <div className="bg-white shadow p-4 rounded">
          <p className="text-sm text-gray-500">Risk</p>
          <p className="text-xl font-bold">{fund.risk}</p>
        </div>
        <div className="bg-white shadow p-4 rounded">
          <p className="text-sm text-gray-500">Minimum SIP Amount</p>
          <p className="text-xl font-bold">{fund.minSIP}</p>
        </div>
        <div className="bg-white shadow p-4 rounded">
          <p className="text-sm text-gray-500">Total Clients Involved</p>
          <p className="text-xl font-bold">{fund.totalClients}</p>
        </div>
        <div className="bg-white shadow p-4 rounded">
          <p className="text-sm text-gray-500">Total Client AUM</p>
          <p className="text-xl font-bold">{fund.totalClientAUM}</p>
        </div>
      </div>
    </div>
  );
};
