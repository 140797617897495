// src/utils/db.js
import { openDB } from 'idb';

const DB_NAME = 'MutualFundsDB';
const DB_VERSION = 1;

// Object Store Names
const STORE_NAME_SCHEME = 'SchemeData';
const STORE_NAME_THRESHOLD = 'ThresholdData';

/**
 * Initializes the IndexedDB database and object stores.
 * @returns {Promise<IDBPDatabase>} - The initialized database instance.
 */
export const initDB = async () => {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME_SCHEME)) {
        db.createObjectStore(STORE_NAME_SCHEME, { keyPath: 'id', autoIncrement: true });
      }
      if (!db.objectStoreNames.contains(STORE_NAME_THRESHOLD)) {
        db.createObjectStore(STORE_NAME_THRESHOLD, { keyPath: 'id', autoIncrement: true });
      }
    },
  });
  return db;
};

/**
 * Adds multiple Scheme records to the SchemeData store.
 * @param {Array<Object>} data - The scheme data to be added.
 */
export const addBulkSchemeData = async (data) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME_SCHEME, 'readwrite');
  const store = tx.objectStore(STORE_NAME_SCHEME);
  for (const record of data) {
    await store.add(record);
  }
  await tx.done;
};

/**
 * Retrieves all records from the SchemeData store.
 * @returns {Promise<Array<Object>>} - All scheme data records.
 */
export const getAllSchemeData = async () => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME_SCHEME, 'readonly');
  const store = tx.objectStore(STORE_NAME_SCHEME);
  const allData = await store.getAll();
  await tx.done;
  return allData;
};

/**
 * Clears all records from the SchemeData store.
 */
export const clearSchemeData = async () => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME_SCHEME, 'readwrite');
  const store = tx.objectStore(STORE_NAME_SCHEME);
  await store.clear();
  await tx.done;
};

/**
 * Adds multiple Threshold records to the ThresholdData store.
 * @param {Array<Object>} data - The threshold data to be added.
 */
export const addBulkThresholdData = async (data) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME_THRESHOLD, 'readwrite');
  const store = tx.objectStore(STORE_NAME_THRESHOLD);
  for (const record of data) {
    await store.add(record);
  }
  await tx.done;
};

/**
 * Retrieves all records from the ThresholdData store.
 * @returns {Promise<Array<Object>>} - All threshold data records.
 */
export const getAllThresholdData = async () => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME_THRESHOLD, 'readonly');
  const store = tx.objectStore(STORE_NAME_THRESHOLD);
  const allData = await store.getAll();
  await tx.done;
  return allData;
};

/**
 * Clears all records from the ThresholdData store.
 */
export const clearThresholdData = async () => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME_THRESHOLD, 'readwrite');
  const store = tx.objectStore(STORE_NAME_THRESHOLD);
  await store.clear();
  await tx.done;
};

/**
 * Retrieves Threshold records matching a specific scheme_code.
 * @param {string} schemeCode - The scheme_code to match.
 * @returns {Promise<Array<Object>>} - Matching threshold data records.
 */
export const getThresholdDataBySchemeCode = async (schemeCode) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME_THRESHOLD, 'readonly');
  const store = tx.objectStore(STORE_NAME_THRESHOLD);
  const allData = await store.getAll();
  await tx.done;
  // Filter records by scheme_code
  return allData.filter(record => record.scheme_code === schemeCode);
};
