import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddNewCategory = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Access navigation state
  const { createCategory, updateCategory } = useContext(DataContext);

  // Form fields
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [isUpdating, setIsUpdating] = useState(false); // Flag for update mode
  const [categoryId, setCategoryId] = useState(null); // Store category ID for updating

  // Load data for updating if passed via navigation state
  useEffect(() => {
    if (location.state) {
      const { id, name, description } = location.state;
      setCategoryId(id);
      setCategoryName(name);
      setDescription(description);
      setIsUpdating(true); // Set update mode
    }
  }, [location.state]);

  // Handle Save (Create or Update Category)
  const handleSave = async () => {
    // Basic validation
    if (!categoryName.trim() || !description.trim()) {
      toast.error("Please fill in all fields.");
      return;
    }

    const categoryData = {
      name: categoryName.trim(),
      description: description.trim(),
    };

    try {
      if (isUpdating) {
        // Update category
        const response = await updateCategory(categoryId, categoryData);
        if (response.success) {
          toast.success("Category updated successfully!");
        } else {
          toast.error("Failed to update category. Please try again.");
        }
      } else {
        // Create category
        const response = await createCategory(
          categoryData.name,
          categoryData.description
        );
        if (response.success) {
          toast.success("Category created successfully!");
        } else {
          toast.error("Failed to create category. Please try again.");
        }
      }
    } catch (error) {
      console.error(isUpdating ? "Failed to update category:" : "Failed to create category:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <ToastContainer /> {/* Toast container to display messages */}
      <button
        className="mb-6 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
        onClick={() => navigate(-1)}
      >
        ← Back
      </button>

      <h1 className="text-2xl font-bold mb-6">
        {isUpdating ? "Update Collection Category" : "Add New Collection Category"}
      </h1>

      <div className="bg-white p-6 shadow rounded">
        {/* Category Name */}
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Category Name</label>
          <input
            type="text"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            placeholder="Enter category name"
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        {/* Description */}
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter description"
            className="w-full p-2 border border-gray-300 rounded"
            rows={4}
          />
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => navigate(-1)}
            className="bg-gray-500 text-white px-6 py-2 rounded hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-600"
          >
            {isUpdating ? "Update" : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewCategory;






// // src/components/AddNewCategory.jsx

// import React, { useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { DataContext } from "../../context/DataContext";
// import { firebaseUploadFile } from "../utilities/firebaseUploadFile";
// import { ToastContainer, toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';

// const AddNewCategory = () => {
//   const navigate = useNavigate();
//   const { createCategory } = useContext(DataContext);

//   const [categoryName, setCategoryName] = useState("");
//   const [description, setDescription] = useState("");
//   const [imageUrl, setImageUrl] = useState("");

//   const [imagePreview, setImagePreview] = useState(null);
//   const [isUploading, setIsUploading] = useState(false);
//   const [uploadProgress, setUploadProgress] = useState(0);

//   const handleImageUpload = async (e) => {
//     const file = e.target.files?.[0];
//     if (!file) return;

//     if (file.size > 1 * 1024 * 1024) { // 1MB limit
//       toast.error("File size should not exceed 1MB.");
//       return;
//     }

//     try {
//       setIsUploading(true);
//       const uploadedImageUrl = await firebaseUploadFile(file, setUploadProgress);
//       setImageUrl(uploadedImageUrl);
//       setImagePreview(uploadedImageUrl);
//       toast.success("Image uploaded successfully!");
//     } catch (error) {
//       console.error("Error uploading image:", error);
//       toast.error("An error occurred while uploading the image.");
//     } finally {
//       setIsUploading(false);
//       setUploadProgress(0);
//     }
//   };

//   const handleSave = async () => {
//     if (!categoryName.trim() || !description.trim() || !imageUrl) {
//       toast.error("Please fill all fields and upload an image.");
//       return;
//     }

//     const categoryData = {
//       name: categoryName.trim(),
//       description: description.trim(),
//       imageUrl,
//     };

//     try {
//       const response = await createCategory(categoryData.name, categoryData.description, categoryData.imageUrl);
//       if (response.success) {
//         toast.success(response.message);
//         navigate(-1); // Navigate back
//       }
//     } catch (error) {
//       console.error("Failed to add category:", error);
//       toast.error("An error occurred while adding the category.");
//     }
//   };

//   return (
//     <div className="p-6 bg-gray-100 min-h-screen">
//       <ToastContainer />
//       <button
//         className="mb-6 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
//         onClick={() => navigate(-1)}
//         disabled={isUploading}
//       >
//         ← Back
//       </button>

//       <h1 className="text-2xl font-bold mb-6">Add New Collection Category</h1>

//       <div className="bg-white p-6 shadow rounded">
//         <div className="mb-6">
//           <label className="block text-gray-700 mb-2">Category Name</label>
//           <input
//             type="text"
//             value={categoryName}
//             onChange={(e) => setCategoryName(e.target.value)}
//             placeholder="Enter category name"
//             className="w-full p-2 border border-gray-300 rounded"
//             disabled={isUploading}
//           />
//         </div>

//         <div className="mb-6">
//           <label className="block text-gray-700 mb-2">Description</label>
//           <textarea
//             value={description}
//             onChange={(e) => setDescription(e.target.value)}
//             placeholder="Enter description"
//             className="w-full p-2 border border-gray-300 rounded"
//             rows={4}
//             disabled={isUploading}
//           />
//         </div>

//         <div className="mb-6 inline-block border-2 border-dashed border-gray-300 p-4 rounded-md">
//           {imagePreview ? (
//             <img
//               src={imagePreview}
//               alt="Preview"
//               className="w-72 h-auto object-cover mb-2"
//             />
//           ) : (
//             <div className="text-gray-400 mb-2 text-center w-72">
//               + Add Picture (Preview appears here)
//             </div>
//           )}
//           <input
//             type="file"
//             onChange={handleImageUpload}
//             accept="image/*"
//             disabled={isUploading}
//           />
//           {isUploading && (
//             <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
//               <div
//                 className="bg-blue-600 h-2.5 rounded-full"
//                 style={{ width: `${uploadProgress}%` }}
//               ></div>
//             </div>
//           )}
//         </div>

//         <div className="flex justify-end space-x-4">
//           <button
//             onClick={() => navigate(-1)}
//             className="bg-gray-500 text-white px-6 py-2 rounded hover:bg-gray-600"
//             disabled={isUploading}
//           >
//             Cancel
//           </button>
//           <button
//             onClick={handleSave}
//             className={`bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-600 ${
//               isUploading ? "opacity-50 cursor-not-allowed" : ""
//             }`}
//             disabled={isUploading}
//           >
//             {isUploading ? "Uploading..." : "Save"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddNewCategory;

