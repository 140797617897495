import React, { useState } from "react";
import image from "./login.svg";

const Login = ({ onLogin }) => {
  const [formData, setFormData] = useState({
    username: "admin@example.com",
    password: "password123",
    rememberPassword: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { username, password } = formData;
    onLogin(username, password);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="hidden md:flex items-center justify-center bg-orange-100 p-6">
          <img
            src={image}
            alt="Login Illustration"
            className="w-72 h-auto"
          />
        </div>
        <div className="p-8 md:p-12 w-full md:w-96">
          <h2 className="text-2xl font-bold text-gray-800">Login to Account</h2>
          <p className="text-gray-600 mb-6">
            Please enter your email and password to continue
          </p>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                Username
              </label>
              <input
                type="email"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="Enter your email"
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-orange-500 focus:border-orange-500"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter your password"
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-orange-500 focus:border-orange-500"
                required
              />
              <a href="/forgot-password" className="text-sm text-orange-500 hover:underline float-right mt-1">
                Forgot Password?
              </a>
            </div>

            <div className="flex items-center mb-6">
              <input
                type="checkbox"
                id="rememberPassword"
                name="rememberPassword"
                checked={formData.rememberPassword}
                onChange={handleChange}
                className="h-4 w-4 text-orange-500 focus:ring-orange-400 border-gray-300 rounded"
              />
              <label htmlFor="rememberPassword" className="ml-2 text-sm text-gray-600">
                Remember Password
              </label>
            </div>

            <button
              type="submit"
              className="w-full bg-orange-500 text-white py-2 px-4 rounded-lg hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
