import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.svg"; // Adjust the path if necessary
import {
  FaTachometerAlt,
  FaShieldAlt,
  FaWallet,
  FaShoppingCart,
  FaBell,
  FaUsers,
  FaClipboardList,
  FaCog,
  FaSignOutAlt,
  FaQuestionCircle,
  FaMoneyBillAlt, // Mutual Funds icon
} from "react-icons/fa";

const sidebarItems = [
  { icon: <FaTachometerAlt />, text: "Dashboard", path: "/" },
  { icon: <FaShieldAlt />, text: "Risk Analyzer", path: "/risk-analyzer" },
  { icon: <FaMoneyBillAlt />, text: "Mutual Funds", path: "/mutual-funds" }, // Added Mutual Funds
  { icon: <FaWallet />, text: "CAN", path: "/can" },
  { icon: <FaShoppingCart />, text: "EpayEezz", path: "/epayeezz" },
  { icon: <FaClipboardList />, text: "Orders", path: "/orders" },
  { icon: <FaBell />, text: "Notifications", path: "/notifications" },
  { icon: <FaQuestionCircle />, text: "Help & Support", path: "/help-support" },
  { icon: <FaUsers />, text: "User Management", path: "/user-management" },
  { icon: <FaClipboardList />, text: "Content", path: "/content" },
  {
    icon: <FaUsers />,
    text: "Role Management",
    path: "/role-management",
    borderTop: true, // Added border-top flag
  },
  { icon: <FaCog />, text: "Settings", path: "/settings" },
  { icon: <FaSignOutAlt />, text: "Logout", path: "/logout" },
];

const SidebarItem = ({ icon, text, path, borderTop, isActive }) => (
  <div> {/* No spacing here */}
    <Link
      to={path}
      className={`flex items-center p-4 text-gray-700 transition-colors duration-300 rounded-md font-medium
              ${borderTop ? "border-t border-gray-300 mt-2" : ""} 
              ${
                isActive
                  ? "bg-black text-white"
                  : "hover:bg-black hover:text-white"
              }`} // Active and hover styles
    >
      <div className="text-xl mr-4">{icon}</div>
      <span className="text-lg">{text}</span>
    </Link>
  </div>
);

const Sidebar = () => {
  const location = useLocation(); // Get the current path

  return (
    <div className="w-[20%] h-screen bg-white border-r-2 px-2 flex flex-col">
      <div className="flex items-center justify-center py-4">
        <img src={logo} alt="brand-logo" />
      </div>
      <div className="flex flex-col gap-2"> {/* Flex container with gap */}
        {sidebarItems.map((item, index) => (
          <SidebarItem
            key={index}
            icon={item.icon}
            text={item.text}
            path={item.path}
            borderTop={item.borderTop}
            isActive={location.pathname === item.path} // Check if the current item is active
          />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
