import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const renderPagination = () => {
    const pageNumbers = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`w-8 h-8 rounded-full ${
              currentPage === i ? "bg-[#000] text-white" : "bg-gray-200"
            }`}
            onClick={() => onPageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      pageNumbers.push(
        <button
          key={1}
          className={`w-8 h-8 rounded-full ${
            currentPage === 1 ? "bg-[#000] text-white" : "bg-gray-200"
          }`}
          onClick={() => onPageChange(1)}
        >
          1
        </button>
      );
      if (currentPage > 3) {
        pageNumbers.push(<span key="dots1">...</span>);
      }
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`w-8 h-8 rounded-full ${
              currentPage === i ? "bg-[#000] text-white" : "bg-gray-200"
            }`}
            onClick={() => onPageChange(i)}
          >
            {i}
          </button>
        );
      }
      if (currentPage < totalPages - 2) {
        pageNumbers.push(<span key="dots2">...</span>);
      }
      pageNumbers.push(
        <button
          key={totalPages}
          className={`w-8 h-8 rounded-full ${
            currentPage === totalPages
              ? "bg-[#000] text-white"
              : "bg-gray-200"
          }`}
          onClick={() => onPageChange(totalPages)}
        >
          {totalPages}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="flex justify-center items-center mt-6 gap-6">
      <button
        className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full"
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <FaChevronLeft />
      </button>
      <div className="flex gap-2">{renderPagination()}</div>
      <button
        className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full"
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <FaChevronRight />
      </button>
    </div>
  );
};

export default Pagination;
