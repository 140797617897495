// src/utilities/excelParser.js
import * as XLSX from 'xlsx';

/**
 * Parses an uploaded Excel file and converts it to JSON.
 * @param {File} file - The uploaded Excel file.
 * @returns {Promise<Array<Object>>} - The parsed data as an array of objects.
 */
export const fetchAndParseExcel = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Convert sheet to JSON with empty strings for empty cells
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" });

        resolve(jsonData);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};
