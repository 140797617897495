// src/components/mutualFunds/SchemeDetailView.js
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getAllSchemeData } from "../utilities/db";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";

const SchemeDetailView = () => {
  const { schemeCode } = useParams();
  const navigate = useNavigate();

  const [scheme, setScheme] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchScheme = async () => {
      try {
        const allSchemes = await getAllSchemeData();
        const selectedScheme = allSchemes.find(
          (s) => s.scheme_code.toUpperCase() === schemeCode.toUpperCase()
        );

        if (!selectedScheme) {
          throw new Error("Scheme not found.");
        }

        setScheme(selectedScheme);
      } catch (err) {
        console.error("Error fetching scheme:", err);
        setError("Failed to fetch scheme details.");
      } finally {
        setLoading(false);
      }
    };

    fetchScheme();
  }, [schemeCode]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <svg
          className="animate-spin h-10 w-10 text-blue-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-red-500 text-lg">{error}</p>
        <button
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center space-x-2"
          onClick={() => navigate(-1)}
        >
          <HiArrowLeft size={20} /> <span>Go Back</span>
        </button>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Back Button */}
      <button
        className="mb-4 flex items-center text-blue-500 hover:underline"
        onClick={() => navigate(-1)}
      >
        <HiArrowLeft size={20} /> <span className="ml-2">Back to Management</span>
      </button>

      <h2 className="text-3xl font-bold mb-6">Scheme Details</h2>

      <div className="bg-white shadow-md rounded p-6 grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <span className="font-medium text-gray-700">Scheme Code:</span>{" "}
          <span className="font-semibold">{scheme.scheme_code}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Fund Code:</span>{" "}
          <span className="font-semibold">{scheme.fund_code}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Plan Name:</span>{" "}
          <span className="font-semibold">{scheme.plan_name}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Scheme Type:</span>{" "}
          <span className="font-semibold">{scheme.scheme_type}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Plan Type:</span>{" "}
          <span className="font-semibold">{scheme.plan_type}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Plan Option:</span>{" "}
          <span className="font-semibold">{scheme.plan_opt}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Dividend Option:</span>{" "}
          <span className="font-semibold">{scheme.div_opt}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">AMFI ID:</span>{" "}
          <span className="font-semibold">{scheme.amfi_id}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Primary ISIN:</span>{" "}
          <span className="font-semibold">{scheme.pri_isin}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Secondary ISIN:</span>{" "}
          <span className="font-semibold">{scheme.sec_isin}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">NFO Start:</span>{" "}
          <span className="font-semibold">{scheme.nfo_start}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">NFO End:</span>{" "}
          <span className="font-semibold">{scheme.nfo_end}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Allotment Date:</span>{" "}
          <span className="font-semibold">{scheme.allot_date}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Reopen Date:</span>{" "}
          <span className="font-semibold">{scheme.reopen_date}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Maturity Date:</span>{" "}
          <span className="font-semibold">{scheme.maturity_date}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Entry Load:</span>{" "}
          <span className="font-semibold">{scheme.entry_load}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Exit Load:</span>{" "}
          <span className="font-semibold">{scheme.exit_load}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Purchase Allowed:</span>{" "}
          <span className="font-semibold">{scheme.pur_allowed}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">NFO Allowed:</span>{" "}
          <span className="font-semibold">{scheme.nfo_allowed}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Redemption Allowed:</span>{" "}
          <span className="font-semibold">{scheme.redeem_allowed}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">SIP Allowed:</span>{" "}
          <span className="font-semibold">{scheme.sip_allowed}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Switch Out Allowed:</span>{" "}
          <span className="font-semibold">{scheme.switch_out_allowed}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Switch In Allowed:</span>{" "}
          <span className="font-semibold">{scheme.Switch_In_Allowed}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">STP Out Allowed:</span>{" "}
          <span className="font-semibold">{scheme.stp_out_allowed}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">STP In Allowed:</span>{" "}
          <span className="font-semibold">{scheme.stp_in_allowed}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">SWP Allowed:</span>{" "}
          <span className="font-semibold">{scheme.swp_allowed}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Demat Allowed:</span>{" "}
          <span className="font-semibold">{scheme.Demat_Allowed}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Category ID:</span>{" "}
          <span className="font-semibold">{scheme.Catg_ID}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Sub-Category ID:</span>{" "}
          <span className="font-semibold">{scheme.Sub_Catg_ID}</span>
        </div>
        <div>
          <span className="font-medium text-gray-700">Scheme Flag:</span>{" "}
          <span className="font-semibold">{scheme.Scheme_Flag}</span>
        </div>
      </div>

      <button
        className="mt-6 bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600 flex items-center justify-end space-x-2"
        onClick={() => navigate(`/scheme-details/${scheme.scheme_code}`)}
      >
        <HiArrowRight size={20} /> <span>See Threshold Data</span>
      </button>
    </div>
  );
};

export default SchemeDetailView;
