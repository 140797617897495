// src/context/DataContext.js

import React, { createContext, useState, useEffect } from "react";
import { database } from "../config/firebaseConfig"; // Import Firebase Realtime Database config
import { ref, set, get, update, remove } from "firebase/database"; // Firebase Realtime Database
import { v4 as uuidv4 } from "uuid"; // UUID library

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [usersData, setUsersData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [riskCategories, setRiskCategories] = useState([]);
  const [questions, setQuestions] = useState({});
  const [mutualFunds, setMutualFunds] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch users data
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersRef = ref(database, "users");
        const snapshot = await get(usersRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const formattedData = Object.entries(data).map(([key, value]) => ({
            id: key,
            ...value,
          }));
          setUsersData(formattedData);
        } else {
          console.log("No users found.");
          setUsersData([]);
        }
      } catch (err) {
        console.error("Error fetching users:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Fetch categories data
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesRef = ref(database, "collection_categories");
        const snapshot = await get(categoriesRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const formattedCategories = Object.entries(data).map(
            ([key, value]) => ({
              id: key,
              ...value,
            })
          );
          setCategories(formattedCategories);
        } else {
          setCategories([]);
        }
      } catch (err) {
        console.error("Error fetching categories:", err);
        setError(err);
      }
    };

    fetchCategories();
  }, []);

  // Create a new category (without imageUrl)
  const createCategory = async (name, description) => {
    try {
      const id = uuidv4(); // Generate unique ID
      const newCategory = { name, description };

      const categoryRef = ref(database, `collection_categories/${id}`);
      await set(categoryRef, newCategory); // Save to Realtime Database

      // Update local state
      setCategories((prev) => [...prev, { id, ...newCategory }]);

      // Return success message
      return { success: true, message: "Category added successfully!" };
    } catch (err) {
      console.error("Error creating category:", err);
      setError(err);
      throw err; // Re-throw to handle in the component
    }
  };

  // Update a category
  const updateCategory = async (id, updatedData) => {
    try {
      const categoryRef = ref(database, `collection_categories/${id}`);
      await update(categoryRef, updatedData);

      // Update local state
      setCategories((prev) =>
        prev.map((category) =>
          category.id === id ? { ...category, ...updatedData } : category
        )
      );

      return { success: true, message: "Category updated successfully!" };
    } catch (err) {
      console.error("Error updating category:", err);
      setError(err);
      throw err;
    }
  };

  // Delete a category
  const deleteCategory = async (id) => {
    try {
      const categoryRef = ref(database, `collection_categories/${id}`);
      await remove(categoryRef);

      // Update local state
      setCategories((prev) => prev.filter((category) => category.id !== id));

      return { success: true, message: "Category deleted successfully!" };
    } catch (err) {
      console.error("Error deleting category:", err);
      setError(err);
      throw err;
    }
  };

  // Fetch risk categories data
  useEffect(() => {
    const fetchRiskCategories = async () => {
      try {
        const riskCategoriesRef = ref(database, "risk_category");
        const snapshot = await get(riskCategoriesRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const formattedRiskCategories = Object.entries(data).map(
            ([key, value]) => ({
              id: key,
              ...value,
            })
          );
          setRiskCategories(formattedRiskCategories);
        } else {
          setRiskCategories([]);
        }
      } catch (err) {
        console.error("Error fetching risk categories:", err);
        setError(err);
      }
    };

    fetchRiskCategories();
  }, []);

  // Create a new risk category
  const createRiskCategory = async (name, description) => {
    try {
      const id = uuidv4(); // Generate unique ID
      const newRiskCategory = { name, description };

      const riskCategoryRef = ref(database, `risk_category/${id}`);
      await set(riskCategoryRef, newRiskCategory); // Save to Realtime Database

      // Update local state
      setRiskCategories((prev) => [...prev, { id, ...newRiskCategory }]);

      // Return success message
      return { success: true, message: "Risk category added successfully!" };
    } catch (err) {
      console.error("Error creating risk category:", err);
      setError(err);
      throw err; // Re-throw to handle in the component
    }
  };

  // Update an existing risk category
  const updateRiskCategory = async (id, updatedData) => {
    try {
      const riskCategoryRef = ref(database, `risk_category/${id}`);
      await update(riskCategoryRef, updatedData);

      // Update local state
      setRiskCategories((prev) =>
        prev.map((category) =>
          category.id === id ? { ...category, ...updatedData } : category
        )
      );

      return { success: true, message: "Risk category updated successfully!" };
    } catch (err) {
      console.error("Error updating risk category:", err);
      setError(err);
      throw err;
    }
  };

  // Delete a risk category
  const deleteRiskCategory = async (id) => {
    try {
      const riskCategoryRef = ref(database, `risk_category/${id}`);
      await remove(riskCategoryRef);

      // Update local state
      setRiskCategories((prev) =>
        prev.filter((category) => category.id !== id)
      );

      return { success: true, message: "Risk category deleted successfully!" };
    } catch (err) {
      console.error("Error deleting risk category:", err);
      setError(err);
      throw err;
    }
  };

  // Create a new question
  const createQuestion = async (questionData) => {
    try {
      const uuid = uuidv4(); // Generate a unique UUID for the question
      const newQuestion = {
        question: questionData.question,
        options: questionData.options, // Ensure options is an array
      };

      const questionRef = ref(database, `questions/${uuid}`); // Set the reference path to 'questions'

      // Save to Firebase
      await set(questionRef, newQuestion);

      // Update local state
      setQuestions((prev) => ({
        ...prev,
        [uuid]: newQuestion,
      }));

      return { success: true, message: "Question created successfully!" };
    } catch (err) {
      console.error("Error creating question:", err);
      setError(err);
      throw err; // Re-throw to handle in the component
    }
  };

  // Update an existing question
  const updateQuestion = async (uuid, questionData) => {
    try {
      const questionRef = ref(database, `questions/${uuid}`);
      await update(questionRef, questionData); // Update in Firebase

      // Update local state
      setQuestions((prev) => ({
        ...prev,
        [uuid]: {
          ...prev[uuid],
          ...questionData,
        },
      }));

      return { success: true, message: "Question updated successfully!" };
    } catch (err) {
      console.error("Error updating question:", err);
      setError(err);
      throw err;
    }
  };

  // Delete a question
  const deleteQuestion = async (uuid) => {
    try {
      const questionRef = ref(database, `questions/${uuid}`);
      await remove(questionRef); // Remove from Firebase

      // Update local state
      setQuestions((prev) => {
        const updatedQuestions = { ...prev };
        delete updatedQuestions[uuid];
        return updatedQuestions;
      });

      return { success: true, message: "Question deleted successfully!" };
    } catch (err) {
      console.error("Error deleting question:", err);
      setError(err);
      throw err;
    }
  };

  // Fetch questions data
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const questionsRef = ref(database, "questions");
        const snapshot = await get(questionsRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          setQuestions(data); // Store as object
        } else {
          setQuestions({});
        }
      } catch (err) {
        console.error("Error fetching questions:", err);
        setError(err);
      }
    };

    fetchQuestions();
  }, []);

  // Fetch mutual funds data
  useEffect(() => {
    const fetchMutualFunds = async () => {
      try {
        const fundsRef = ref(database, "mutual_funds");
        const snapshot = await get(fundsRef);
        if (snapshot.exists()) {
          setMutualFunds(snapshot.val());
        } else {
          setMutualFunds({});
        }
      } catch (err) {
        console.error("Error fetching mutual funds:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchMutualFunds();
  }, []);

  // Create a new mutual fund
  const createMutualFund = async (fundData) => {
    try {
      const id = uuidv4(); // Generate a unique ID for the fund
      const newFund = { ...fundData, clients: "0" }; // Default clients count

      const mutualFundRef = ref(database, `mutual_funds/${id}`);
      await set(mutualFundRef, newFund);

      setMutualFunds((prev) => ({ ...prev, [id]: newFund }));
      return { success: true, message: "Mutual fund created successfully!" };
    } catch (err) {
      console.error("Error creating mutual fund:", err);
      setError(err);
      throw err;
    }
  };

  // Update an existing mutual fund
  const updateMutualFund = async (id, updatedData) => {
    try {
      const mutualFundRef = ref(database, `mutual_funds/${id}`);
      await update(mutualFundRef, updatedData);

      setMutualFunds((prev) => ({
        ...prev,
        [id]: { ...prev[id], ...updatedData },
      }));

      return { success: true, message: "Mutual fund updated successfully!" };
    } catch (err) {
      console.error("Error updating mutual fund:", err);
      setError(err);
      throw err;
    }
  };

  // Delete a mutual fund
  const deleteMutualFund = async (id) => {
    try {
      const mutualFundRef = ref(database, `mutual_funds/${id}`);
      await remove(mutualFundRef);

      setMutualFunds((prev) => {
        const updatedFunds = { ...prev };
        delete updatedFunds[id];
        return updatedFunds;
      });

      return { success: true, message: "Mutual fund deleted successfully!" };
    } catch (err) {
      console.error("Error deleting mutual fund:", err);
      setError(err);
      throw err;
    }
  };

  // Bulk upload mutual funds
  const bulkUploadMutualFunds = async (bulkData) => {
    try {
      const updates = {};
      bulkData.forEach((fund) => {
        const id = uuidv4();
        updates[`mutual_funds/${id}`] = { ...fund, clients: "0" };
      });

      await update(ref(database), updates);

      setMutualFunds((prev) => ({ ...prev, ...updates }));
      return { success: true, message: "Bulk upload completed!" };
    } catch (err) {
      console.error("Error in bulk upload:", err);
      setError(err);
      throw err;
    }
  };

  return (
    <DataContext.Provider
      value={{
        usersData,
        categories,
        createCategory,
        updateCategory,
        deleteCategory,
        riskCategories,
        createRiskCategory,
        updateRiskCategory,
        deleteRiskCategory,
        questions,
        createQuestion,
        updateQuestion,
        deleteQuestion,
        mutualFunds,
        createMutualFund,
        updateMutualFund,
        deleteMutualFund,
        bulkUploadMutualFunds,
        loading,
        error,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
