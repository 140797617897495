import React from "react";
import { useNavigate } from "react-router-dom";

const ContentManagement = () => {
  const navigate = useNavigate();

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Content Management</h1>
      <div className="grid gap-4">
        <button
          className="bg-orange-100 p-4 rounded shadow flex justify-between items-center"
          onClick={() => navigate("/content/banners")}
        >
          <div className="flex flex-col items-start">
            <h2 className="font-bold text-lg">Homescreen Banner</h2>
            <p>Total Three Banners</p>
          </div>
          <span className="text-orange-800 text-3xl">&rarr;</span>
        </button>
        <button
          className="bg-orange-100 p-4 rounded shadow flex justify-between items-center"
          onClick={() => navigate("/content/faqs")}
        >
           <div className="flex flex-col items-start">
            <h2 className="font-bold text-lg">FAQs</h2>
            <p>Total Four FAQ Categories</p>
          </div>
          <span className="text-orange-800 text-3xl">&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default ContentManagement;
