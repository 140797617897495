// Import the functions you need from the SDKs you need
// Import the necessary Firebase SDKs
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // For Firestore
import { getDatabase } from "firebase/database"; // For Realtime Database
import { getAuth } from "firebase/auth"; // For Authentication
import { getStorage } from "firebase/storage"; // For Storage
// import { getAnalytics } from "firebase/analytics"; // For Analytics (Optional)
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyByI8nMuzhAgMx27I6DmiNdu5J7jsDq8-c",
  authDomain: "solid-wealth-5eef6.firebaseapp.com",
  databaseURL: "https://solid-wealth-5eef6-default-rtdb.firebaseio.com",
  projectId: "solid-wealth-5eef6",
  storageBucket: "solid-wealth-5eef6.firebasestorage.app",
  messagingSenderId: "587672887945",
  appId: "1:587672887945:web:652211c4c3e1c1cccfdc58",
  measurementId: "G-71YXQT1B9H"
};
const app = initializeApp(firebaseConfig);

// Initialize Firebase Services
const firestore = getFirestore(app);
const database = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);
export { app, firestore, database, auth, storage };