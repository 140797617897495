import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaTrash, FaPlus, FaChevronLeft } from "react-icons/fa";
import { DataContext } from "../../context/DataContext";

const RiskProfiles = () => {
  const navigate = useNavigate();
  const { riskCategories, deleteRiskCategory } = useContext(DataContext); // Access context values
  const [categories, setCategories] = useState(riskCategories); // Initialize state with fetched categories
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  useEffect(() => {
    setCategories(riskCategories); // Update local state when context data changes
  }, [riskCategories]);

  const handleDeleteCategory = async (id) => {
    setCategoryToDelete(id);
    setShowDeleteConfirm(true); // Show confirmation prompt
  };

  const confirmDelete = async () => {
    if (categoryToDelete) {
      const response = await deleteRiskCategory(categoryToDelete); // Delete category from DB
      alert(response.message);
      setShowDeleteConfirm(false); // Close confirmation
      setCategoryToDelete(null); // Reset category to delete
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirm(false); // Close confirmation without deleting
    setCategoryToDelete(null); // Reset category to delete
  };

  return (
    <div className="p-8">
      <div className="flex items-center justify-between mb-8">
        <button
          className="mb-4 text-2xl font-bold text-[#000000] flex items-center"
          onClick={() => navigate(-1)}
        >
          <FaChevronLeft className="mr-2" size={20} /> Risk Profiles
        </button>

        <button
          className="flex items-center bg-[#FF9800] text-gray-800 px-4 py-2 rounded-md mb-4"
          onClick={() => navigate("/add-category")}
        >
          <FaPlus className="mr-2" /> Add New Category
        </button>
      </div>

      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h3 className="text-xl font-semibold mb-4">Confirm Deletion</h3>
            <p>Are you sure you want to delete this category?</p>
            <div className="mt-4 flex justify-between">
              <button
                className="bg-gray-300 px-4 py-2 rounded-md"
                onClick={cancelDelete}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md"
                onClick={confirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <table className="w-full bg-white shadow-md rounded-lg">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-4 text-center">S No.</th>
            <th className="p-4 text-center">Category Name</th>
            <th className="p-4 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => (
            <tr key={category.id} className="border-b">
              <td className="p-4 text-center">{index + 1}</td>
              <td className="p-4 text-center">{category.name}</td>
              <td className="p-4 items-center flex justify-center space-x-2">
                <button
                  className="bg-yellow-500 text-white px-2 py-1 rounded-md"
                  onClick={() => 
                    navigate("/add-category", {
                      state: { id: category.id, name: category.name, description: category.description },
                    })} // Navigate with data for editing
                >
                  <FaEdit />
                </button>
                <button
                  className="bg-red-500 text-white px-2 py-1 rounded-md"
                  onClick={() => handleDeleteCategory(category.id)} // Trigger delete confirmation
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RiskProfiles;
