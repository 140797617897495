import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import Navbar from "./components/navbar/Navbar";
import {Dashboard} from "./components/dashboard/Dashboard";
import RiskAnalyzer from "./components/riskAnalyzer/RiskAnalyzer";
import CreateQuestion from "./components/riskAnalyzer/CreateQuestion";
import QuestionDetail from "./components/riskAnalyzer/QuestionDetail";
import RiskProfiles from "./components/riskAnalyzer/RiskProfiles";
import AddCategory from "./components/riskAnalyzer/AddCategory";
import CANManagement from "./components/canManagement/CANManagement";
import MutualFundsManagement from "./components/mutualFunds/MutualFundsManagement";
import TrendingFunds from "./components/mutualFunds/TrendingFunds";
import AddTrendingFund from "./components/mutualFunds/AddTrendingFund";
import CollectionCategories from "./components/mutualFunds/CollectionCategories";
import AddNewCategory from "./components/mutualFunds/AddNewCategory";
// import EditCategory from "./components/mutualFunds/EditCategory";
import EpayeezzManagement from "./components/epayeezzManagement/EpayeezzManagement";
import UserDetailForm from "./components/epayeezzManagement/UserDetailForm";
import OrderManagement from "./components/orders/OrderManagement";
import OrderDetail from "./components/orders/OrderDetail";
import Notifications from "./components/notifications/Notifications";
import HelpSupport from "./components/helpSupport/HelpSupport";
import QueryDetail from "./components/helpSupport/QueryDetail";
import UserManagement from "./components/userManagement/UserManagement";
import UserDetail from "./components/userManagement/UserDetail";
import UserBankDetails from "./components/userManagement/UserBankDetails";
import ContentManagement from "./components/contentManagement/ContentManagement";
import BannerManagement from "./components/contentManagement/BannerManagement";
import FAQCategories from "./components/contentManagement/FAQCategories";
import FAQDetails from "./components/contentManagement/FAQDetails";
import AddFAQCategory from "./components/contentManagement/AddFAQCategory";
import RoleManagement from "./components/roleManagement/RoleManagement";
import AddRole from "./components/roleManagement/AddRole";
import RoleDetails from "./components/roleManagement/RoleDetails";
import LoginCredentials from "./components/roleManagement/LoginCredentials";
import { GeneralSettings } from "./components/settings/GeneralSettings";
import Login from "./components/loginComponent/Login";
import Logout from "./components/loginComponent/Logout";
import { FundDetails } from "./components/mutualFunds/FundDetails";
import SchemeDetails from "./components/mutualFunds/SchemeDetails";
import SchemeDetailView from "./components/mutualFunds/SchemeDetailView";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = (username, password) => {
    // Static credentials for now
    const validUsername = "admin@example.com";
    const validPassword = "password123";

    if (username === validUsername && password === validPassword) {
      setIsAuthenticated(true);
    } else {
      alert("Invalid credentials. Please use a valid email and password.");
    }
  };

  return (
    <Router>
      <div className="flex">
        {isAuthenticated && <Sidebar />}
        <div className={isAuthenticated ? "w-[80%]" : "w-full"}>
          {isAuthenticated && <Navbar />}
          <Routes>
            {!isAuthenticated ? (
              <Route path="/*" element={<Login onLogin={(username, password) => handleLogin(username, password)} />} />
            ) : (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/risk-analyzer" element={<RiskAnalyzer />} />
                <Route path="/create-question" element={<CreateQuestion />} />
                <Route path="/edit-question/:id" element={<CreateQuestion />} />
                <Route path="/risk-profiles" element={<RiskProfiles />} />
                <Route path="/add-category" element={<AddCategory />} />
                <Route path="/edit-category/:id" element={<AddCategory />} />
                <Route path="/mutual-funds" element={<MutualFundsManagement />} />
                <Route path="/scheme-details/view/:schemeCode" element={<SchemeDetailView />} /> {/* New Route */}
                <Route path="/scheme-details/:schemeCode" element={<SchemeDetails />} />
                <Route path="/fund-details" element={<FundDetails />} />
                <Route path="/trending-funds" element={<TrendingFunds />} />
                <Route path="/add-trending-fund" element={<AddTrendingFund />} />
                <Route path="/collection-categories" element={<CollectionCategories />} />
                <Route path="/add-new-category" element={<AddNewCategory />} />
                {/* <Route path="/edit-category" element={<EditCategory />} /> */}
                <Route path="/can" element={<CANManagement />} />
                <Route path="/epayeezz" element={<EpayeezzManagement />} />
                <Route path="/user-details/:id" element={<UserDetailForm />} />
                <Route path="/orders" element={<OrderManagement />} />
                <Route path="/orders/:id" element={<OrderDetail />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/help-support" element={<HelpSupport />} />
                <Route path="/query/:id/:status" element={<QueryDetail />} />
                <Route path="/user-management" element={<UserManagement />} />
                <Route path="/user/:id" element={<UserDetail />} />
                <Route path="/user/:id/bank-details" element={<UserBankDetails />} />
                <Route path="/content" element={<ContentManagement />} />
                <Route path="/content/banners" element={<BannerManagement />} />
                <Route path="/content/faqs" element={<FAQCategories />} />
                <Route path="/content/faqs/:category" element={<FAQDetails />} />
                <Route path="/content/faqs/add" element={<AddFAQCategory />} />
                <Route path="/role-management" element={<RoleManagement />} />
                <Route path="/role-management/add" element={<AddRole />} />
                <Route path="/role-management/:roleId" element={<RoleDetails />} />
                <Route path="/role-management/:roleId/login" element={<LoginCredentials />} />
                <Route path="/settings" element={<GeneralSettings />} />
                <Route path="/logout" element={<Logout />} />
              </>
            )}
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;

// https://wealthez.in/api/v1/login, 
// entity id = 400089