import React from 'react'
import MutualFundsManagement from '../mutualFunds/MutualFundsManagement'

export const Dashboard = () => {
  return (
    <div>
       <MutualFundsManagement />
    </div>
  )
}
