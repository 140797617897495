import React, { useState, useEffect } from 'react';
import { FaFilter, FaChevronLeft, FaChevronRight, FaSearch, FaDownload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

// Dummy Data
const usersData = [
  { id: 'SW01', name: 'Lorem Ipsum', email: 'lorem1@example.com', phone: '+91-9911988726', holdingNature: 'Single', status: 'Verified' },
  { id: 'SW02', name: 'Lorem Ipsum', email: 'lorem2@example.com', phone: '+91-9911988726', holdingNature: 'Joint', status: 'Pending' },
  { id: 'SW03', name: 'Lorem Ipsum', email: 'lorem3@example.com', phone: '+91-9911988726', holdingNature: 'Anyone or Survivor', status: 'Verified' },
  { id: 'SW04', name: 'Lorem Ipsum', email: 'lorem4@example.com', phone: '+91-9911988726', holdingNature: 'Joint', status: 'Verified' },
  { id: 'SW05', name: 'Lorem Ipsum', email: 'lorem5@example.com', phone: '+91-9911988726', holdingNature: 'Single', status: 'Rejected' },
];

const CANManagement = () => {
  const [filteredUsers, setFilteredUsers] = useState(usersData);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 5;
  const navigate = useNavigate();

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filtered = usersData.filter(user =>
      user.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredUsers(filtered);
    setCurrentPage(1);
  };

  const nextPage = () => currentPage < totalPages && setCurrentPage(prev => prev + 1);
  const prevPage = () => currentPage > 1 && setCurrentPage(prev => prev - 1);

  const getStatusBadge = (status) => {
    switch (status) {
      case 'Verified': return 'bg-green-100 text-green-700';
      case 'Pending': return 'bg-yellow-100 text-yellow-700';
      case 'Rejected': return 'bg-red-100 text-red-700';
      default: return 'bg-gray-100 text-gray-700';
    }
  };

  return (
    <div className="flex">
      {/* Sidebar */}
   

      {/* Main Content */}
      <div className="flex-1 p-8">
        {/* Header */}
        <div className="text-2xl font-semibold mb-6">CAN Registration Management</div>

        {/* Stats Cards */}
        <div className="grid grid-cols-4 gap-6 mb-6">
          {[
            { title: 'Total CAN Registrations', count: 1093, color: 'bg-orange-100' },
            { title: 'New Registrations', count: 50, color: 'bg-purple-100' },
            { title: 'Pending Registrations', count: 10, color: 'bg-yellow-100' },
            { title: 'Verified Registrations', count: 100, color: 'bg-green-100' }
          ].map((card, index) => (
            <div key={index} className={`p-4 rounded-lg ${card.color} shadow-md`}> 
              <div className="text-lg font-bold mb-2">{card.title}</div>
              <div className="text-3xl font-bold">{card.count}</div>
            </div>
          ))}
        </div>

        {/* Search & Export */}
        <div className="flex justify-between mb-4">
          <div className="flex space-x-4">
            <input
              type="text"
              placeholder="Search..."
              className="border px-4 py-2 rounded-md focus:ring-2 focus:ring-blue-400"
              value={searchTerm}
              onChange={handleSearch}
            />
            <button className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 flex items-center">
              <FaDownload className="mr-2" /> Export
            </button>
          </div>
        </div>

        {/* Table */}
        <div className="border rounded-lg overflow-hidden">
          <table className="w-full text-left">
            <thead className="bg-gray-100">
              <tr>
                <th className="p-4">User ID</th>
                <th className="p-4">User Full Name</th>
                <th className="p-4">Email / Mobile No.</th>
                <th className="p-4">Holding Nature</th>
                <th className="p-4">Status</th>
                <th className="p-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map(user => (
                <tr key={user.id} className="hover:bg-gray-50">
                  <td className="p-4">{user.id}</td>
                  <td className="p-4">{user.name}</td>
                  <td className="p-4">{user.email} / {user.phone}</td>
                  <td className="p-4">{user.holdingNature}</td>
                  <td className={`p-4 text-center rounded-lg ${getStatusBadge(user.status)}`}>{user.status}</td>
                  <td className="p-4 text-orange-500 cursor-pointer hover:underline">Review</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex items-center justify-center mt-6">
          <button onClick={prevPage} className="p-2 bg-gray-200 rounded-full mx-2">
            <FaChevronLeft />
          </button>
          {[...Array(totalPages)].map((_, idx) => (
            <button
              key={idx}
              onClick={() => setCurrentPage(idx + 1)}
              className={`p-2 rounded-full mx-1 ${currentPage === idx + 1 ? 'bg-black text-white' : 'bg-gray-200'}`}
            >
              {idx + 1}
            </button>
          ))}
          <button onClick={nextPage} className="p-2 bg-gray-200 rounded-full mx-2">
            <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CANManagement;
